<template>
  <iframe :src="$props.mapUrl" style="border:0;" loading="lazy"></iframe>
</template>

<script>
export default {
  name: "Map",
  props: ["mapUrl"]
}
</script>

<style scoped lang="scss">
iframe {
  width: calc(100% + 40px);
  height: 320px;
  position: relative;
  left: -20px;
}
</style>